<template>
  <div class="changelog">
    <div v-html="markdownHtml" class="markdown-body"></div>
  </div>
</template>

<script>
import { marked } from "marked";
import changelogMarkdown from "../../../../changelog.md";

export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {};
  },
  computed: {
    markdownHtml() {
      return marked.parse(changelogMarkdown);
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
@import url(https://cdnjs.cloudflare.com/ajax/libs/github-markdown-css/5.1.0/github-markdown.min.css);
</style>
